import toast from 'izitoast';

export function info(title, message) {
    toast.info({
        title,
        message: message || '',
        position: 'bottomCenter',
        color: '#1f2128',
        titleColor: '#F4F1D0',
        messageColor: '#F4F1D0',
        iconColor: '#F4F1D0',
        icon: '',
        theme: 'dark',
    });
}

export function error(title, message) {
    toast.error({
        title,
        message: message || '',
        position: 'bottomCenter',
        color: '#ae4442',
        titleColor: '#F4F1D0',
        messageColor: '#F4F1D0',
        iconColor: '#F4F1D0',
        icon: '',
        theme: 'dark',
    });
}

