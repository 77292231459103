import axios, { AxiosInstance } from 'axios';

type ApiResponse = [string | null, any | null];

declare module 'axios' {
  export interface AxiosInstance {
    request(config: AxiosRequestConfig): Promise<ApiResponse>;
    get(url: string, config?: AxiosRequestConfig): Promise<ApiResponse>;
    delete(url: string, config?: AxiosRequestConfig): Promise<ApiResponse>;
    head(url: string, config?: AxiosRequestConfig): Promise<ApiResponse>;
    post(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse>;
    put(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse>;
    patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse>;
  }
}

const api = axios.create({
    baseURL: '/api',
    withCredentials: true
});

function onSuccess(response) {
    return [null, response.data];
}

function onError(error) {
    if (error.response) return [error.response.data.error, null];
    if (error.request) return ['Network Error', null];
    return ['Unknown Error', null];
}

api.interceptors.response.use(onSuccess, onError);

const actions = {
    // TODO move to rpc
    postWantSearch: async function postWantSearch(query) {
        return api.post('/want/search', { query });
    },

    postDiscogs: async function postDiscogs(username) {
        return api.post('/want/discogs', { username });
    },

    // --------------------------------
    // require as http to set cookies

    getLogin: async function getLogin() {
        return api.get('/auth/login')
    },

    postLogin: async function postLogin(email, password) {
        return api.post('/auth/login', {
            email,
            password,
            // discogs,
        });
    },

    postLogout: async function postLogout() {
        return api.post('/auth/logout', {});
    },

    postRegister: async function postRegister(email, password) {
        return api.post('/auth/register', {
            email,
            password,
        });
    },
};

export default actions;
