import { h } from 'preact';
import { route } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux'
import LogRocket from 'logrocket';

import http from './../http';
import { State } from './../store';

import Swirl from './swirl';

function showAdmin(user) {
    return user && user.email === 'ntr@mnml.gg';
}

const DEFAULT_ROUTE = '/';

const Nav = () => {
    const user = useSelector((state: State) => state.user);

    useEffect(() => {
        if (!user) {
            route('/login');
            return;
        }
        route(DEFAULT_ROUTE)

        if (window.location.host === 'matescrates.au') {
            LogRocket.identify(user.id, {
                name: user.email,
                email: user.email,
            });
        }

        return;
    }, [user])

    async function logoutClick(e) {
        e.preventDefault();
        await http.postLogout();
        window.location.reload();
    }

    const nav = () => (
        <nav>
            <a href="/">Offers</a>
            <a href='/list'>List</a>
            <a href="/search">Search</a>
        </nav>
    );

    const anonNav = () => (
        <nav>
            <a href="/login">Login</a>
            <a href="/register">Register</a>
        </nav>
    );

    const adminNav = () => (
        <nav>
            <a href='/acp'>Offers ACP</a>
        </nav>
    )

    const userNav = () => (
        <nav>
            <a href="/info">Info</a>
            <a href="/auth/logout" onClick={logoutClick}>Logout</a>
        </nav>
    )

    return (
        <header>
            {user ? nav() : anonNav()}
            {showAdmin(user) ? adminNav() : <div />}
            {user ? userNav() : null}
        </header>
    );
}

export default Nav;
