import { createStore, combineReducers } from 'redux'
import { configureStore, createSlice } from '@reduxjs/toolkit'

export const userState = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        set: (state, action) => action.payload
    },
});

export const offersState = createSlice({
    name: 'offers',
    initialState: null,
    reducers: {
        set: (state, action) => action.payload
    },
});

export const acpOffersState = createSlice({
    name: 'acpOffers',
    initialState: [],
    reducers: {
        set: (state, action) => action.payload
    },
});

export const wantsState = createSlice({
    name: 'wants',
    initialState: null,
    reducers: {
        set: (state, action) => action.payload
    },
});

export const wsState = createSlice({
    name: 'ws',
    initialState: null,
    reducers: {
        set: (state, action) => action.payload
    },
});

const reducer = combineReducers({
    offers: offersState.reducer,
    wants: wantsState.reducer,
    ws: wsState.reducer,
    user: userState.reducer,

    acpOffers: acpOffersState.reducer,
});

const store = createStore(
    reducer,
    // @ts-ignore -- devtools window exists on dev
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

export type State = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
